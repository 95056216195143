import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../partial/TextValidator";
import CKEditor from "ckeditor4-react";
import SmsSetting from "./SmsSetting";
import { EMAIL_TEMPLATE_COLUMN } from "./emailTemplateCol";
import SelectValidator from "../../partial/SelectValidator";
import { customerPortalSetting, deleteCustomerPortalSetting, getCustomerPortalSetting } from "../campaigns/services";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getDropdownObject } from "../../../utils/helper";
import EmailTemplateModal from "./EmailTemplateModal";

function EmailTemplate() {
    const [templateModal, showTemplateModal] = useState({
        isOpen: false,
        body: "",
    });
       const [error, setError] = useState(null);
    const [typeDisplay] = useState([
        { value: "Transaction", label: "Transaction" },
        { value: "Redemption", label: "Redemption" },
        { value: "Voucher Purchase", label: "Voucher Purchase" },
        { value: "Giftcard Purchase", label: "GiftCard Purchase" },
    ]);
    const [loader, setLoader] = useState(false);
    const [emailSetting, setEmailSetting] = useState({
        EmailTemplateSetting: [
            {
                EmailType: "",
                Subject: "",
                Body: "",
                fromEmail: "",
                fromName: "",
                sendgridApiKey: "",
                status: false,
            },
        ],
        emailTemplateData: {
            EmailType: "",
            Subject: "",
            Body: "",
            fromEmail: "",
            templateName: "",
            selectEmailType: "",
            fromName: "",
            sendgridApiKey: "",
            status: false,
        },
    });

    const handleViewModal = (body) => {
        showTemplateModal((prev) => ({
            ...prev,
            isOpen: true,
            body: body,
        }));
    };

    const handleDeleteSMS = (id, type) => {
        setLoader(true);
        const tempToken = JSON.parse(localStorage.getItem("token_gen"))?.access_token;

        deleteCustomerPortalSetting(tempToken, id).then((res) => {
            setLoader(false);
            if (res.message === "Settings updated successfully") {
                swal({
                    position: "center",
                    icon: "success",
                    title: `${type} delete Successfully`,
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchDataBanner();
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };

    const fetchDataBanner = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const res = await getCustomerPortalSetting(token?.access_token);

        if (res.message === "Settings retrieved successfully") {
            const { EmailTemplateSetting } = res.data;
            if (EmailTemplateSetting?.length > 0) {
                EmailTemplateSetting.map((val) => {
                    val.action = (
                        <Link className="edit_btn" onClick={() => handleViewModal(val.Body)}>
                            <div className="show_div">View</div>
                        </Link>
                    );
                    val.status = val.status?.toString();
                    val.delete = (
                        <Link onClick={() => handleDeleteSMS(val.id, "Email Template")}>
                            <i className="fa fa-times-circle" style={{ fontSize: "18px" }}></i>
                        </Link>
                    );
                });
            }
            setEmailSetting((prev) => ({
                ...prev,
                EmailTemplateSetting: EmailTemplateSetting,
            }));
        }
        setLoader(false);
    };

    useEffect(() => {
        fetchDataBanner();
    }, []);
    useEffect(()=>{
        const { emailTemplateData, ...rest } = emailSetting;
        const { templateName, selectEmailType, ...restEmailTemplate } = emailTemplateData;  
        if (selectEmailType || templateName) {
            setError("");
           
            return;
        }
    },[emailSetting])

    const handleSubmitEmail = async () => {
        const tempToken = JSON.parse(localStorage.getItem("token_gen"))?.access_token;
        const { emailTemplateData, ...rest } = emailSetting;
        const { templateName, selectEmailType, ...restEmailTemplate } = emailTemplateData;
        const templateSetting = {
            EmailTemplateSetting: [restEmailTemplate],
        };
        const body = { EmailTemplateSetting: templateSetting.EmailTemplateSetting, };
        if (!selectEmailType && !templateName) {
            setError("Either Email Template or Template Name must have a value.");
           
            return;
        }
        else {
            
            setLoader(true);
            customerPortalSetting(tempToken, body).then((res) => {
                setLoader(false);
                if (res.message === "Settings Updated Successfully") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Email Template Setting Set Successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    fetchDataBanner();
                    setEmailSetting((prev) => ({
                        ...prev,
                        emailTemplateData: {
                            EmailType: "",
                            Subject: "",
                            Body: "",
                            fromEmail: "",
                            templateName: "",
                            selectEmailType: "",
                            fromName: "",
                            sendgridApiKey: "",
                            status: false,
                        },
                    }));
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            });
        }
     
    };

    const onChangeEmail = (e, toggle) => {
        if (toggle === "toggle") {
            setEmailSetting((prev) => ({
                ...prev,
                emailTemplateData: {
                    ...prev.emailTemplateData,
                    status: !emailSetting?.emailTemplateData?.status
                }
            }))
        } else {
            if (toggle === "templateName") {
                return setEmailSetting((prev) => ({
                    ...prev,
                    emailTemplateData: {
                        ...prev.emailTemplateData,
                        EmailType: e.target.value,
                        [e.target.name]: e.target.value,
                        selectEmailType: ""
                    }
                }))
            }
            setEmailSetting((prev) => ({
                ...prev,
                emailTemplateData: {
                    ...prev.emailTemplateData,
                    [e.target.name]: e.target.value
                }
            }))
        }

    }

    const handleTypeChange = (e) => {
        setEmailSetting((prev) => ({
            ...prev,
            emailTemplateData: {
                ...prev.emailTemplateData,
                EmailType: e.value,
                selectEmailType: e.value,
                templateName: "",
            },
        }));
    };

    const handleEditorChange = (e) => {
        const data = e.editor.getData();
        setEmailSetting((prev) => ({
            ...prev,
            emailTemplateData: {
                ...prev.emailTemplateData,
                Body: data,
            },
        }));
    };
    return (
        <div>
            <Header name={"Email Template"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="dashboard__transactionsCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id="transction_list">Email Template</h4>
                        </div>
                    </div>
                    <ValidatorForm className="dashboard__customerViewMain" onSubmit={handleSubmitEmail}>
                    <div className="dashboard__customerViewCard">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>Subject</label>
                                    <TextValidator placeholder="Subject" className="form-control" name="Subject" onChange={onChangeEmail} value={emailSetting?.emailTemplateData?.Subject} validators={["required"]} errorMessages={["Subject is required"]} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>From Name</label>
                                    <TextValidator placeholder="Name" type="text" className="form-control" name="fromName" onChange={onChangeEmail} value={emailSetting?.emailTemplateData?.fromName} validators={["required"]} errorMessages={["Name is required"]} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>Form Email</label>
                                    <TextValidator
                                        placeholder="novus@loyalty.com"
                                        className="form-control"
                                        name="fromEmail"
                                        onChange={onChangeEmail}
                                        value={emailSetting?.emailTemplateData?.fromEmail}
                                        validators={["required", "isEmail"]}
                                        errorMessages={["From email is required", "Email is not valid"]}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>Send Grid APIkey</label>
                                    <TextValidator
                                        placeholder="API Key"
                                        type="text"
                                        className="form-control"
                                        name="sendgridApiKey"
                                        onChange={onChangeEmail}
                                        value={emailSetting?.emailTemplateData?.sendgridApiKey}
                                        validators={["required"]}
                                        errorMessages={["API Key is required"]}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>Email Template</label>
                                    <div className="row">
                                        <div className="col-11">
                                            <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="selectEmailType" value={emailSetting?.emailTemplateData?.selectEmailType ? getDropdownObject(emailSetting?.emailTemplateData?.selectEmailType, typeDisplay) : ""} options={typeDisplay} onChange={(e) => handleTypeChange(e, "email")}  
                                        //      validators={["required"]}
                                        // errorMessages={["Email Template is required"]} 
                                        />
                                           {error && <div style={{ color: "red" }}>{error}</div>}
                                        </div>
                                        <div className="col-1 text-center px-0 pt-2">
                                            OR
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group withIcon">
                                    <label>&nbsp;</label>
                                    <TextValidator
                                        placeholder="Enter template name"
                                        className="form-control"
                                        name="templateName"
                                        onChange={(e) => onChangeEmail(e, "templateName")}
                                        value={emailSetting?.emailTemplateData?.templateName}
                                        // validators={["required"]}
                                        // errorMessages={["Template name is required"]}

                                    />
                                </div>
                            </div>


                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <div className="person" style={{ display: 'flex', alignItems: "center", width: "100%" }}>
                                    <p className="voucher mb-2" style={{ display: "flex", width: "25%", marginBottom: "0", color: "black" }}>
                                        Status
                                    </p>
                                    <div className="">
                                        <label className="toggle">
                                            <input type="checkbox" name="status" checked={emailSetting?.emailTemplateData?.status} onChange={(e) => onChangeEmail(e, "toggle")} />
                                            <span className="slider"></span>
                                            <span className="labels" data-on="Active" data-off="Inactive"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12">
                                <CKEditor
                                    config={{ versionCheck: false }}
                                    data={emailSetting?.emailTemplateData?.Body}
                                    onChange={handleEditorChange}
                                    name="Body"
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">
                                <div className="button-section" style={{ justifyContent: "flex-end" ,display:"flex"}}>
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div>
                                <SmsSetting columns={EMAIL_TEMPLATE_COLUMN} data={emailSetting?.EmailTemplateSetting || []} />
                            </div>
                        </div>
                        </div>
                    </ValidatorForm>
                    {templateModal?.isOpen && <EmailTemplateModal show={templateModal?.isOpen} close={() => showTemplateModal((prev) => ({ ...prev, isOpen: false }))} body={templateModal.body} header={"Email Template"} />}
                </div>
            </div>
        </div>
    )
}

export default EmailTemplate