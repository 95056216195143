import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
// import axios from "axios";

export const Token_Generator = async () => {
    try {
        const fetchResponse = await fetch(baseURL + "tokengenerator", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const Internal_Token = async (body) => {
    try {
        const fetchResponse = await fetch(baseURL + "internaltoken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const signin = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "signin", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const signup = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "signup", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const forgetPassword = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "forget", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            auth: (token, { type: "bearer" }),
            body: JSON.stringify(data),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const resetPassword = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "reset", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            auth: (token, { type: "bearer" }),
            body: JSON.stringify(data),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const signupConfirmation = async (token, id) => {
    try {
        const fetchResponse = await fetch(`${baseURL}confirmsignup?id=${id}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            auth: (token, { type: "bearer" }),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const validateCSV = async (file) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
        const fetchResponse = await fetch(`${baseURL}validateCSV`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const customerBulkUpload = async (file, token) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    try {
        const fetchResponse = await fetch(`${baseURL}customerbulkupload`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const transactionBulkUpload = async (file, token) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");

    try {
        const fetchResponse = await fetch(`${baseURL}transactionbulkupload`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const transactionValidateCSV = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        const fetchResponse = await fetch(`${baseURL}transactionValidateCSV`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const createToken = async (file) => {
    try {
        const fetchResponse = await fetch(`${baseURL}createToken`, {
            method: "POST",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const giftCard = async (country) => {
    try {
        const fetchResponse = await fetch(`${baseURL}giftCard?country=${country}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const giftCardView = async (code) => {
    try {
        const fetchResponse = await fetch(`${baseURL}giftCardView?code=${code}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getSelectedGiftCard = async (country, code) => {
    try {
        const fetchResponse = await fetch(`${baseURL}getSelectedGiftCard?country=${country}&&code=${code}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const getSelectGiftCard = async ( client) => {
    try {
        const fetchResponse = await fetch(`${baseURL}getSelectGiftCard?client=${client}`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const giftCardUpdate = async (body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}giftCardUpdate`, {
            method: "POST",
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const RegisterUser = async (file) => {
    try {
        const fetchResponse = await fetch(`${baseURL}registerUser`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const ContactUsData = async (file) => {
    try {
        const fetchResponse = await fetch(`${baseURL}getContactUsData`, {
            method: "GET",
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getIndustry = async (token) => {
    try {
        const fetchResponse = await fetch(baseURL + "getindustry", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
export const getAllTrasaction = async (token,id,offset,limit) => {
    try {
        const fetchResponse = await fetch(baseURL + `transaction?id=${id}&offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
export const getAllCustomer = async (token,id,offset,limit,searchValue) => {
    try {
        const fetchResponse = await fetch(baseURL + `mergedCustomer?id=${id}&offset=${offset}&limit=${limit}&searchValue=${searchValue}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};



export const customerValidateUserJourneyCSV = async (file) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
        const fetchResponse = await fetch(`${baseURL}customerValidateUserJourneyCSV`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const customerValidateUserJourneyCSVTEXT = async (file) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
        const fetchResponse = await fetch(`${baseURL}customerValidateUserJourneyCSVTEXT`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const transactionValidateUserJourneyCSV = async (file) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
        const fetchResponse = await fetch(`${baseURL}transactionValidateUserJourneyCSV`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const transactionValidateUserJourneyCSVTEXT = async (file) => {
    // file.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
        const fetchResponse = await fetch(`${baseURL}transactionValidateUserJourneyCSVTEXT`, {
            method: "POST",
            body: formData,
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const histTransactionBulkUpload = async (file, token, isHistorical, isMultiple, fileCode, schemaName) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(`${baseURL}histTransactionbulkupload?isHistorical=${isHistorical}&isMultiple=${isMultiple}&fileCode=${fileCode}&schemaName=${schemaName}`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const histCustomerBulkUpload = async (file, token, isHistorical, isMultiple, fileCode, schemaName) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(`${baseURL}histCustomerbulkupload?isHistorical=${isHistorical}&isMultiple=${isMultiple}&fileCode=${fileCode}&schemaName=${schemaName}`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const histTransactionBulkUploadTEXT = async (file, token, isHistorical, isMultiple, fileCode) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(`${baseURL}histTransactionbulkupload?isHistorical=${isHistorical}&isMultiple=${isMultiple}&fileCode=${fileCode}`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const histCustomerBulkUploadTEXT = async (file, token, isHistorical, isMultiple, fileCode) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(`${baseURL}histCustomerbulkupload?isHistorical=${isHistorical}&isMultiple=${isMultiple}&fileCode=${fileCode}`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const fileUploadDetails = async (token, id, type) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `fileuploaddetails?id=${id}&filetype=${type}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
export const getMerchantAndLogo = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getMerchantNameAndLogo?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getMerchantAndBanner = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getMerchantNameAndBanner?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
export const deleteBanner = async (token, id,blobFilePath) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL +`deleteBanner?id=${id}&blobFilePath=${blobFilePath}`, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const deleteVoucherImageData = async (token,voucherTemplateCode) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL +`deleteVoucherImage?voucherTemplateCode=${voucherTemplateCode}`, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
export const getVouchersImage = async (token, id,voucherTemplateCode) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL +`getVoucherImage?id=${id}&voucherTemplateCode=${voucherTemplateCode}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const GetSchemabyMerchantCode = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "GetSchemabyMerchant?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const BarCode = async (token,values) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `barcode?values=${values}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
export const RedeemVoucher = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "redeemvoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const GenerateVoucher = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "generateVoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const SendGridEmail = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "sendGrid", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const DownloadVoucher = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "downloadVoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const CountryRegions = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "CountryRegions", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const merchantGiftCard = async (body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}merchantGiftCard`, {
            method: "POST",
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};


export const UpdateTrasactionProcessing = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "minimumOrder", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    
    } catch (error) {
        return error;
    }
};

export const getProgramPoint = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getProgram", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const emailReport = async (token ,type,purpose) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
            let apiUrl = baseURL + "getEmailReport";
          
            if (type) {
                apiUrl += `?type=${type}&purpose=${purpose}`;
            }
        const fetchResponse = await fetch(apiUrl, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};



export const PostThemeData = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "postTheme", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};


export const SocialMediaData = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "socialMediaUpdate", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};


export const GetThemeData = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getTheme", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const deleteThemeData = async (token,themeid) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
         
        const fetchResponse = await fetch(baseURL +`deleteTheme?themeid=${themeid}`, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const PostSelectedData = async (token, selectedValue, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });

        const fetchResponse = await fetch(baseURL + `postSelectedTheme?selectedValue=${selectedValue}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getCustomerReview = async (token, merCode) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getCustomerReview?merCode=${merCode}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getMembersipData = async (token, merchantId) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getMembersip?merchantId=${merchantId}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const getUploadData = async (token, merchantId) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getUploadInvoice?merchantId=${merchantId}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const updateCustomerReview = async (token, body, id, remarks, status) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `updateCustomerReview?id=${id}&remarks=${remarks}&status=${status}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};



export const whatsappMessage = async (token, version, numId, body) => {
    // const valid = await isTokenExpired(token);
    try {
        // if (!valid)
        //     await getNewAuthToken().then((res) => {
        //         localStorage.setItem("token_gen", JSON.stringify(res));
        //         token = res?.access_token;
        //     });
        const fetchResponse = await fetch(baseURL + `whatsapp?version=${version}&numId=${numId}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getWhatsappTemplate = async (token, version, id) => {
    try {
        const fetchResponse = await fetch(baseURL + `getWhatsappTemplate?version=${version}&id=${id}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const createTier = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `createTier`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
