import React, { useState, useEffect, useMemo, memo } from "react";
import Header from "../../layout/Header";
import CampaignDetailsTab from "./CampaignDetailsTab";
import EarningRulesTab from "./referral/EarningRulesTab";
import { backIcon2 } from "../../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import response from "../../../reducers/response.json";
import { decrypt } from "../../../utils/encodingdecoding";
// import { useLocation } from "react-router-dom";

const EditReferralCampaign = memo(() => {
    // const location = useLocation()
    // const {code_id} = location?.state

    let navigate = useNavigate();
    const [key, setKey] = useState("Campaign Details");
    const [formateData, SetFormateData] = useState({
        version: "15",
        code: "IBM-CAM000059",
        coalitionCampaignCode: null,
        coalitionProgramCode: null,
        status: true,
        priority: 999,
        name: "",
        description: null,
        timeZone: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        isTest: false,
        isVisibile: true,
        startDateTime: "2022-08-24T00:00:00",
        endDateTime: "2023-12-31T00:00:00",
        visibilityDateTime: "0001-01-01T00:00:00",
        offerDetail: {
            offerCode: "",
            title: "Referral Campaign",
            programCode: null,
            internalLink: null,
            externalLink: null,
            imageLink: null,
            mobileImageLink: null,
            logoImageLink: null,
            description: null,
            redemptionProcess: null,
            escalationMatrix: null,
            termAndCondition: null,
            purchaseable: {
                isPurchaseable: false,
                isActivated: false,
                price: 0,
                validityPeriod: 86400,
                validityPeriodType: 0,
                isImported: false,
                voucherTemplate: null,
            },
            longDescription: null,
        },
        tags: [],
        internalTags: [],
        earningRule: {
            earningRuleEvalutionType: 2,
            rules: [
                {
                    infixRule: [
                        {
                            entityName: "Transaction",
                            field: "Referral_Type",
                            jsonPath: "$.Transaction[?(@.Referral_Type=='Referrer')]",
                            op: "equal",
                            value: ["Referrer"],
                            type: "string",
                            format: null,
                        },
                    ],
                    prefixRule: {
                        condition: "AND",
                        rules: [
                            {
                                id: "Referral_Type-7",
                                field: "Referral_Type-7",
                                type: "string",
                                input: "select",
                                operator: "equal",
                                value: "Referrer",
                                data: {
                                    class: "Transaction",
                                },
                            },
                        ],
                        valid: true,
                    },
                    data: [
                        {
                            RedeemType: "Fix-Point",
                            Value: 5,
                            isEvery: false,
                            RewardRatio: 100,
                            EarnigWho: "Customer",
                            Sponsors: [
                                {
                                    Type: "Merchant",
                                    Code: decrypt(localStorage.getItem("merchantcode")),
                                    Percent: 100,
                                },
                            ],
                            redeemRule: {
                                redeemRuleBy: false,
                                afterSpecificTransaction: false,
                            },
                            expirationRule: {
                                onAfter: true,
                                afterSpecificNumber: 365,
                                afterDuration: "Day(s)",
                            },
                        },
                    ],
                    function: [
                        {
                            infixRule: [],
                            prefixRule: null,
                            duration: null,
                        },
                    ],
                    dbFunctionRule: [
                        {
                            sqlRule: null,
                            infixRule: [],
                            prefixRule: null,
                        },
                    ],
                },
                {
                    infixRule: [
                        {
                            entityName: "Transaction",
                            field: "Referral_Type",
                            jsonPath: "$.Transaction[?(@.Referral_Type=='Referees')]",
                            op: "equal",
                            value: ["Referees"],
                            type: "string",
                            format: null,
                        },
                    ],
                    prefixRule: {
                        condition: "AND",
                        rules: [
                            {
                                id: "Referral_Type-7",
                                field: "Referral_Type-7",
                                type: "string",
                                input: "select",
                                operator: "equal",
                                value: "Referees",
                                data: {
                                    class: "Transaction",
                                },
                            },
                        ],
                        valid: true,
                    },
                    data: [
                        {
                            RedeemType: "Fix-Point",
                            Value: 5,
                            isEvery: false,
                            RewardRatio: 100,
                            EarnigWho: "Customer",
                            Sponsors: [
                                {
                                    Type: "Merchant",
                                    Code: decrypt(localStorage.getItem("merchantcode")),
                                    Percent: 100,
                                },
                            ],
                            redeemRule: {
                                redeemRuleBy: false,
                                afterSpecificTransaction: false,
                            },
                            expirationRule: {
                                onAfter: true,
                                afterSpecificNumber: 365,
                                afterDuration: "Day(s)",
                            },
                        },
                    ],
                    function: [
                        {
                            infixRule: [],
                            prefixRule: null,
                            duration: null,
                        },
                    ],
                    dbFunctionRule: [
                        {
                            sqlRule: null,
                            infixRule: [],
                            prefixRule: null,
                        },
                    ],
                },
            ],
        },
        InfixQualifyingRules: [],
        PrefixQualifyingRules: [],
        merchantTransactionSchema: {
            schemaId: "51adcf2f-51db-4251-92fa-23927e7cb383",
            schemaName: "Novus Loyalty Schema",
            merchants: ["MER000027"],
        },
        processingMode: 0,
        approvalStatus: 1,
        restrictedPrograms: [],
        campaignGlobalLimit: {
            isGlobalCampaignLimit: false,
            globalLimits: [
                {
                    walletKey: "point",
                    walletName: "point",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "discount",
                    walletName: "discount",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "currency",
                    walletName: "currency",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
            ],
        },
        campaignCustomerLimit: {
            isCustomerLimit: false,
            customerLimits: [
                {
                    walletKey: "point",
                    walletName: "point",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
                {
                    walletKey: "discount",
                    walletName: "discount",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
                {
                    walletKey: "currency",
                    walletName: "currency",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
            ],
        },
        selfMadeCampaign: false,
        taglastcheck: null,
        type: "",
        Range: 0,
        Tiers: null,
        Membership: null,
        merchantId: "MER000027",
    });
    useEffect(() => {
        SetFormateData(response.data[0]);
    }, [response]); //eslint-disable-line

    const onChange = (e) => {
        if (e === false || e === true) {
            SetFormateData({ ...formateData, ...{ status: e } });
        } else {
            SetFormateData({ ...formateData, ...{ [e.target.name]: e.target.value } });
        }
    };

    const renderEarningRulesTab = useMemo(() => {
        return <EarningRulesTab onChange={(e) => onChange(e)} formateData={formateData} />;
    }, [formateData]); //eslint-disable-line

    return (
        <>
            <Header name={"Edit Referral Program"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingBackLink">
                                        <Link to="" onClick={() => navigate(-1)}>
                                            <img src={backIcon2} alt="" />
                                        </Link>
                                    </div>
                                    <div className="dashboard__headingContent">
                                        <h3>Edit campaign</h3>
                                        <p>Edit how your customers will earn points</p>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__tabSection">
                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                                    <Tab eventKey="Campaign Details" title="Campaign Details">
                                        <CampaignDetailsTab onChange={(e) => onChange(e)} formateData={formateData} />
                                    </Tab>
                                    <Tab eventKey="Rules" title="Rules">
                                        {renderEarningRulesTab}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    // auth: state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditReferralCampaign);
