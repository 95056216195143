import React, { useState, useEffect, memo } from "react";
import Header from "../layout/Header";
import Paginated from "./customersList/Paginated";
import { Link } from "react-router-dom";
import { COLUMNS } from "./customersList/column";
// eslint-disable-next-line
import { importIcon } from "../../Image/Index";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../actions/customer";
import { decrypt } from "../../utils/encodingdecoding";
import { COLUMNS1 } from "./customersList/column1";
import { getAllCustomer } from "../../actions/auth";

const CustomersList = memo(({ getCustomer, Customer, clearCustomer }) => {
    const [data, setData] = useState([]);
    const [customerCount, setCustomerCount] = useState();
    const [response, setResponse] = useState([]);
    const [loader, setLoader] = useState(true);
    let id = decrypt(localStorage.getItem("Id"))
    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        if (tempPageIndex === 1) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setResponse(Customer?.data)
    }, [Customer])

    useEffect(() => {

        const temp = response?.data?.data;

        if (temp) {

            temp?.map((val) => {
                return (

                    val.name = val.customerData.name,
                    val.expiredValue = val.walletData.wallet.expiredValue,
                    val.customerId = val.customerData.customerid,
                    val.email = val.customerData.email,
                    val.mobile = val.customerData.mobile,
                    val.earnedValue = val.walletData.wallet.earnedValue,
                    val.availableValue = val.walletData.wallet.availableValue,
                    val.redeemValue = val.walletData.wallet.redeemValue,
                    val.action = val.customerData.customercode
                )
            })

            setData(temp);
        }
    }, [response]);

    const fetch = async () => {
        setLoader(true);
        await clearCustomer();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getCustomer(token?.access_token, id, 1, 50, " ");
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    };
    // eslint-disable-next-line
  const generateCSV = () => {
        const datas = [["Customer Id", "Earned Value", "Available Value", "Redeem Value","Expired Value"]];

        data.forEach((transaction) => {
            const rowData = [
                transaction?.customerId, transaction.earnedValue,transaction.availableValue,transaction.redeemValue,transaction.expiredValue
            ];

            datas.push(rowData);
        });

        const csvContent = "data:text/csv;charset=utf-8," + datas.map((row) => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
    };

        useEffect(() => {
            setCustomerCount(Customer?.data?.data?.totalCount);
        }, [Customer]);
    return (
        <>
            <Header name={"Customer"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer Data</h4>
                            </div>
                            <div className="dashboard__right">
                                <Link to="/uploading-existing-datas" id="upload_customer" state={{ from: "customer" }} className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Customer
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing customer data.</p>
                            <p>You can add the data by uploading a CSV file containing the details.</p>
                        </div>
                    </div>
                    <div className="dashboard__customersCard">
                    <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer List</h4>
                            </div>
                            <div className="dashboard__right">
                                <button     onClick={generateCSV} className="btn btn-default">
                                <i class="fa-solid fa-download" style={{marginRight:"10px"}}></i>Download Customer
                                </button>
                            </div>
                        </div>

                        <div className="dashboard__body">
                  
                          
                            {id === "MER000252" ?

                                <Paginated data={data} columns={COLUMNS1} loader={loader} setLoader={setLoader} />
                                :

                                <Paginated data={data} columns={COLUMNS} loader={loader} setLoader={setLoader} />

                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
