import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { getDate } from "../../../utils/helper";
import { getUploadData } from "../../../actions/auth";
import { transactionRegistration, UpdateUploadInVoiceData } from "../campaigns/services";
import swal from "sweetalert";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../../actions/customer";

const UploadInvoice = ({ getCustomer, Customer, clearCustomer }) => {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [totaldata, setTotalData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [remarks, setRemarks] = useState({});
    const [cusData, setCusData] = useState([]);

    const fetch = async () => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        const id = localStorage.getItem("Id");
        try {
            const res = await getUploadData(tempToken, id);
            const cusData = getCustomer(tempToken, id, 1, 10000, " ")
            setFormData(res.data);
            
        } catch (error) {
            console.error("Error fetching upload data:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);


    useEffect(() => {
        setCusData(Customer?.data?.data);
    }, [Customer]);


    useEffect(() => {
  
        if (formData?.length > 0) {
        
            const tempData = formData.map((ele) => {
          
                if (cusData?.data?.length > 0) {
                    const flag = cusData.data.find((cus) => cus.customerData.email === ele.email);
                    if (flag) {
                        return {
                            ...ele,
                            name: flag.customerData.name,
                          
                        };
                    }
                }
                return ele;
            });
            setTotalData(tempData?.length ? tempData : []);
    
        }
    }, [cusData, formData]);
    
    useEffect(() => {
        const tempData = totaldata.map((val) => {
            val.title = val.invoicetitle ? val.invoicetitle : "-";
            val.name = val.name ? val.name : "-";
            val.email = val.email ? val.email : "-";
            const image1 = val?.imagesData[0]?.blobImageUrl || "";

        const image2 = val?.imagesData[1]?.blobImageUrl || "";
        val.image = [image1, image2]  ;

            val.date = getDate(val.uploadedDate, "DD-MMM-YYYY");
            if (val?.isApproved === true) {
                val.status = "Approved";
            } else if (val.isApproved === false) {
                val.status = "Rejected";
            } else {
                val.status = (
                    <div>
                        <button className="btn btn-success btn-sm d-inline-flex" onClick={() => handleApprove(val)}>Approve</button>
                        <button className="btn btn-danger btn-sm d-inline-flex ml-2" onClick={() => handleReject(val)}>Reject</button>
                    </div>
                );
            }
            val.remarkId = val?.merchantFeedback?.length > 0 ? val.merchantFeedback : <input
                type="text"
                value={remarks[val.invoiceId] || ""}
                onChange={(e) => handleRemarkChange(val.invoiceId, e.target.value)}
                placeholder="Enter remark"
            />;
            return val;
        });
        setData(tempData);
    }, [totaldata, remarks]);
   

    const handleRemarkChange = (id, value) => {
        setRemarks((prevRemarks) => ({
            ...prevRemarks,
            [id]: value,
        }));
    };

    const handleApprove = async (val) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        const invoiceId = val?.invoiceId;
        const remark = remarks[val?.invoiceId] || "N/A";
        try {
            const tempReview = await UpdateUploadInVoiceData(tempToken?.access_token, invoiceId, true, "N/A", remark);
            const transBody = {
                activity: "photoupload",
                email: val?.email,
                schemacode: "TSC000022",
            };
            const transData = await transactionRegistration(tempToken?.access_token, JSON.stringify(transBody));
            if (transData.message === "Request processed successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Invoice data updated successfully.",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetch()
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: "Invoice data not updated successfully.",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
            setRemarks("")
        } catch (error) {
            console.error("Error updating invoice:", error);
        } finally {
            setLoader(false);
        }
    };

    const handleReject = async (val) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        const invoiceId = val?.invoiceId;
        const remark = remarks[val?.invoiceId] || "N/A";
        try {
            const tempReview = await UpdateUploadInVoiceData(tempToken?.access_token, invoiceId, false, "N/A", remark);
            if (tempReview.message === "Invoice data updated successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Invoice data updated successfully.",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetch()
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: tempReview.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
            setRemarks("")
        } catch (error) {
            console.error("Error updating invoice:", error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Upload Invoice"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Upload Invoice Details</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated data={data || []} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoice);
