import React, { useRef, useState, useEffect, memo } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate } from "react-router-dom";
import { getDate, parseNumeric, selectValueGetter, selectValueGetterBoolean, getOperator } from "../utils/helper";
import Switch from "react-switch";
import TextValidator from "../components/partial/TextValidator";
import SelectValidator from "../components/partial/SelectValidator";
import swal from "sweetalert";
import { decrypt, encrypt } from "../utils/encodingdecoding";
import { connect } from "react-redux";
import ModalCard from "../components/layout/ModalCard";
import { voucherTemplateDetail, clearVoucherTemplate } from "../actions/voucher";
import introJs from 'intro.js';
import 'intro.js/introjs.css'; 
import { campaign ,schema} from "../components/pages/campaigns/services";
const CreateEarningRulesTabLoyalty = memo((props) => {
    const {VoucherTemplate, voucherTemplateDetail, clearVoucherTemplate, formateData, handleTabChange, formPage }=props
    const formRef = useRef();
    const navigate = useNavigate();
    const intro = introJs();
    const [formError, setFormError] = useState("");
    // const { formateData, handleTabChange, formPage } = props;
    let exitOnLast=false
    const [toggle, setToggle] = useState("AND");
    const [finalData, setFinalData] = useState({});
    const [incomingTransactionsCount2, setIncomingTransactionsCount2] = useState(1);
    const [toggleAnd2, setToggleAnd2] = useState("AND");
    const [showModal,setShowmodal]=useState(false)
    const [addDelBtn, setAddDelBtn] = useState(true); //eslint-disable-line
    const [incomingTransactions, setIncomingTransactions] = useState({
        data: [
            {
                id: 1,
                transactionAmount: "",
                greaterOrEqual: "",
                quantity: "",
                quantity1: "",
                amt: [],
                isFlag: false,
                andOr: "AND",
                type: "",
                position: 0,
            },
        ],
        EarnigWho: "Customer",
        RedeemType: "",
        RewardRatio: "",
        TransactionFieldForEvery: "",
        Value: 0,
        everyValue: 0,
        isEvery: true,
        CapLimit: "",
        TransactionField: "",
        VoucherTemplate: "",
        VoucherEarningValue: "",
    });

    const [incomingTransactions2, setIncomingTransactions2] = useState({
        data: [
            {
                id: 1,
                transactionAmount: "",
                greaterOrEqual: "",
                quantity: "",
                amt: [],
                isFlag: false,
                andOr: "AND",
            },
        ],
        EarnigWho: "Customer",
        RedeemType: "",
        RewardRatio: "",
        Value: 0,
    });
    const [advanceRule, setAdvanceRule] = useState({
        field: "",
        input: "",
        value: "",
        value2: "",
        type: "",
        everyValue: "",
        isEvery: false,
    });
    const [rewardratioData, setRewardRatiodata] = useState({
        EarnigWho: "Customer",
        RedeemType: "",
        // RewardRatio: 0,
        RewardRatio: "",
        expirationRule: {
            afterDuration: "",
            afterSpecificNumber: 365,
            onAfter: true,
            onSpecificDate: "",
        },
    });
    const [stringCondition] = useState([
        { operator: "=", label: "equal", value: "equal" },
        { operator: "!=", label: "not equal", value: "not_equal" },
        { operator: "==", label: "in", value: "in" },
        { operator: "!=", label: "not in", value: "not_in" },
        // { operator: "!", label: "is not null", value: "is_not_null" },
    ]);
    const [numberCondition] = useState([
        { operator: "=", label: "equal", value: "equal" },
        { operator: "!=", label: "not equal", value: "not_equal" },
        { operator: "<", label: "less", value: "less" },
        { operator: "<=", label: "less or equal", value: "less_or_equal" },
        { operator: ">", label: "greater", value: "greater" },
        { operator: ">=", label: "greater or equal", value: "greater_or_equal" },
        { operator: "", label: "between", value: "between" },
    ]);
    const [transactionOptionAdv] = useState([
        // { label: "Select", value: "" },
        { label: "TransactionCount", value: "TransactionCount" },
    ]);

    const [booleanCondition] = useState([{ label: "equal", value: "equal" }]);

    const [formData, setFormData] = useState({
        optionPoint:
            formPage === "voucher"
                ? [
                    // { value: "Fix-Point", label: "Fix- Point" },
                    // { value: "Percentage-Point", label: "Percentage-Point" },
                    { value: "Voucher", label: "Voucher" },
                ]
                : [
                    { value: "Fix-Point", label: "Fix-Point" },
                    { value: "Percentage-Point", label: "Percentage-Point" },
                    // { value: "Voucher", label: "Voucher" },
                ],
        optionOnAfter: [
            { value: true, label: "On" },
            { value: false, label: "After" },
        ],
        optionAfter: [{ value: "Day(s)", label: "Day(s)" }],
        transactionOption: [],
        amountOption: [],
        conditions: [],
        voucherOption: [],
    });
    const [isAdvance, setIsAdvance] = useState(false);
    const [data, setData] = useState();
    const [loader, setLoader] = useState(false);
    const { optionPoint, optionOnAfter, optionAfter, transactionOption, amountOption, conditions, voucherOption } = formData;
    const { expirationRule } = rewardratioData;
    const { afterDuration, afterSpecificNumber, onAfter, onSpecificDate } = expirationRule;
    useEffect(() => {
      //  fetch();
    }, []);
    // useEffect(() => {
    //     fetchVoucherTemplate();
    // }, []);

    useEffect(() => {
        setFinalData({ ...finalData, finalData: formateData });
    }, [formateData]); //eslint-disable-line

    useEffect(() => {
        const infixRule = [];
        const prefixRule = [];
        const DBInfix = [];
        const DBPrefix = [];
        var sqlRule = `{\"sql\":\"`; //eslint-disable-line
        var condition = "";
        //eslint-disable-next-line
        incomingTransactions?.data?.map((e, ind) => {
            var op = getOperator(conditions, e.greaterOrEqual);
            if (typeof e === "object" && ind % 2 === 0) {
                condition = e.greaterOrEqual === "between" ? `c.transactionDetail.${e.transactionAmount} <= ${Number(e.quantity)} AND c.transactionDetail.${e.transactionAmount} >= ${Number(e.quantity1)}` : e.type === "string" ? `c.transactionDetail.${e.transactionAmount} ` + op?.operator + " " + `'` + e.quantity + `'` : `c.transactionDetail.${e.transactionAmount} ` + op?.operator + " " + e.quantity;
                prefixRule.push({
                    id: e.transactionAmount + "-" + e.position,
                    field: e.transactionAmount + "-" + e.position,
                    type: e.type,
                    input: e.type === "string" ? "text" : "integer",
                    operator: e.greaterOrEqual,
                    value: e.type === "string" ? e.quantity : e.greaterOrEqual === "between" ? [Number(e.quantity), Number(e.quantity1)] : Number(e.quantity),
                    data: {
                        class: "Transaction",
                    },
                });
                sqlRule = sqlRule + condition;
                infixRule.push({
                    id: e.transactionAmount + "-" + e.position,
                    entityName: "Transaction",
                    field: e.transactionAmount,
                    format: null,
                    jsonPath: e.greaterOrEqual === "between" ? `$.Transaction[?(@.${e.transactionAmount}>=${e.quantity} && @.${e.transactionAmount} >=${e.quantity1})]` : `$.Transaction[?(@.${e.transactionAmount}${op?.operator}${e.quantity ? e.quantity : e.amt[0]})]`,
                    operator: e.greaterOrEqual,
                    type: e.type,
                    value: e.greaterOrEqual === "between" ? [e.quantity, e.quantity1] : e.quantity,
                    data: {
                        class: "Transaction",
                    },
                });
                DBPrefix.push({
                    id: "c.transactionDetail." + e.transactionAmount,
                    field: "c.transactionDetail." + e.transactionAmount,
                    type: e.type,
                    input: e.type === "string" ? "text" : "integer",
                    operator: e.greaterOrEqual,
                    value: e.type === "string" ? e.quantity : e.greaterOrEqual === "between" ? [Number(e.quantity), Number(e.quantity1)] : Number(e.quantity),
                    data: {
                        class: "Transaction",
                    },
                });
                DBInfix.push({
                    id: "c.transactionDetail." + e.transactionAmount,
                    // entityName: "Transaction",
                    field: "c.transactionDetail." + e.transactionAmount,
                    // format: null,
                    jsonPath: e.greaterOrEqual === "between" ? `$.Transaction[?(@.${e.transactionAmount}>=${e.quantity} && @.${e.transactionAmount} >=${e.quantity1})]` : `$.Transaction[?(@.${e.transactionAmount}${op?.operator}${e.quantity ? e.quantity : e.amt ? e.amt[0] : "0"})]`,
                    operator: e.greaterOrEqual,
                    type: e.type,
                    value: e.greaterOrEqual === "between" ? [e.quantity, e.quantity1] : e.quantity,
                    data: {
                        class: "Transaction",
                    },
                });
            } else {
                infixRule.push(toggle);
                DBInfix.push(toggle);
                sqlRule = sqlRule + ` ${toggle} `;
            }
        });

        setFinalData((prevState) => ({
            finalData: {
                ...prevState.finalData,
                earningRule: {
                    ...prevState.finalData.earningRule,
                    rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
                        ind === 0
                            ? {
                                ...rules,
                                infixRule: infixRule,
                                prefixRule: { condition: toggle, valid: true, rules: prefixRule },
                                dbFunctionRule: rules?.dbFunctionRule?.map((value, index) => (index === 0 && isAdvance ? { sqlRule: sqlRule + '"}', infixRule: DBInfix, prefixRule: { condition: toggle, valid: true, rules: DBPrefix } } : formateData?.earningRule?.rules[0]?.dbFunctionRule[0])),
                            }
                            : rules
                    ),
                },
            },
        }));
    }, [incomingTransactions?.data, isAdvance]); //eslint-disable-line

    useEffect(() => {
        // const infixRule = { class: {} };
        // const prefixRule = { data: {} };
        const InfixRule = [];
        const PrefixRule = [];
        PrefixRule.push({
            id: advanceRule.field,
            field: advanceRule.field,
            type: "integer",
            input: "number",
            operator: advanceRule.input,
            value: Number(advanceRule.value),
            data: {
                class: "Functions",
            },
        });
        InfixRule.push({
            id: advanceRule.field,
            field: advanceRule.field,
            type: "integer",
            input: "number",
            operator: advanceRule.input,
            value: Number(advanceRule.value),
            data: {
                class: "Functions",
            },
        });

        setFinalData((prevState) => ({
            finalData: {
                ...prevState.finalData,
                earningRule: {
                    ...prevState.finalData.earningRule,
                    rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
                        ind === 0
                            ? {
                                ...rules,
                                // dbFunctionRule: rules?.dbFunctionRule?.map((value, index) => (index === 0 && isAdvance ? { sqlRule: sqlRule, infixRule: InfixRule, prefixRule: { condition: toggle, valid: true, rules: PrefixRule } } : formateData?.earningRule?.rules[0]?.dbFunctionRule[0])),
                                function: rules?.function?.map((value, index) => (index === 0 && advanceRule.field !== "" ? { infixRule: InfixRule, prefixRule: { condition: toggle, valid: true, rules: PrefixRule }, duration: "Day" } : formateData?.earningRule?.rules[0]?.function[0])),
                            }
                            : rules
                    ),
                },
            },
        }));
    }, [advanceRule]); //eslint-disable-line

    useEffect(() => {
        setFinalData((prevState) => ({
            finalData: {
                ...prevState.finalData,
                earningRule: {
                    ...prevState.finalData.earningRule,
                    rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
                        ind === 0
                            ? {
                                ...rules,
                                data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
                                    index === 0 && incomingTransactions.RedeemType === "Percentage-Point"
                                        ? {
                                            // ...data,
                                            Value: Number(incomingTransactions.Value),
                                            EarnigWho: incomingTransactions.EarnigWho,
                                            RedeemType: incomingTransactions.RedeemType,
                                            RewardRatio: Number(incomingTransactions.RewardRatio),
                                            TransactionFieldForEvery: advanceRule.isEvery ? advanceRule.type : "",
                                            isEvery: advanceRule.isEvery,
                                            everyValue: advanceRule.isEvery ? advanceRule.everyValue : 0,
                                            TransactionField: incomingTransactions.TransactionField,
                                            CapLimit: Number(incomingTransactions.CapLimit),
                                            Sponsors: data.Sponsors,
                                            expirationRule: data.expirationRule,
                                            redeemRule: data.redeemRule,
                                        }
                                        : index === 0 && incomingTransactions.RedeemType === "Voucher"
                                            ? {
                                                RedeemType: incomingTransactions.RedeemType, //
                                                VoucherTemplate: incomingTransactions.VoucherTemplate,
                                                isEvery: advanceRule.isEvery, //
                                                EarnigWho: incomingTransactions.EarnigWho, //
                                                Sponsors: data.Sponsors, //
                                                expirationRule: data.expirationRule, //
                                                redeemRule: data.redeemRule, //
                                                Value: Number(incomingTransactions.VoucherEarningValue), //
                                                // RewardRatio: Number(incomingTransactions.RewardRatio), //
                                                TransactionFieldForEvery: advanceRule.isEvery ? advanceRule.type : "", //
                                                everyValue: advanceRule.isEvery ? advanceRule.everyValue : 0,
                                                earning_frequency: true,
                                            }
                                            : index === 0
                                                ? {
                                                    // ...data,
                                                    Sponsors: data.Sponsors, //
                                                    expirationRule: data.expirationRule, //
                                                    redeemRule: data.redeemRule, //
                                                    Value: Number(incomingTransactions.Value), //
                                                    EarnigWho: incomingTransactions.EarnigWho, //
                                                    RedeemType: incomingTransactions.RedeemType, //
                                                    RewardRatio: Number(incomingTransactions.RewardRatio), //
                                                    TransactionFieldForEvery: advanceRule.isEvery ? advanceRule.type : "", //
                                                    isEvery: advanceRule.isEvery, //
                                                    everyValue: advanceRule.isEvery ? advanceRule.everyValue : 0, //
                                                }
                                                : data
                                ),
                            }
                            : rules
                    ),
                },
            },
        }));
    }, [incomingTransactions, advanceRule]);

    useEffect(() => {
        setFinalData((prevState) => ({
            finalData: {
                ...prevState.finalData,
                earningRule: {
                    ...prevState.finalData.earningRule,
                    rules: prevState?.finalData?.earningRule?.rules.map((rules, ind) =>
                        ind === 0
                            ? {
                                ...rules,
                                data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
                                    index === 0
                                        ? {
                                            ...data,
                                            expirationRule: onAfter
                                                ? {
                                                    ...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
                                                    afterDuration: afterDuration,
                                                    afterSpecificNumber: Number(afterSpecificNumber),
                                                    onAfter: onAfter,
                                                }
                                                : {
                                                    ...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
                                                    onAfter: onAfter,
                                                    onSpecificDate: onSpecificDate,
                                                    onAfterSame: false,
                                                },
                                        }
                                        : data
                                ),
                            }
                            : ind === 1
                                ? {
                                    ...rules,
                                    data: prevState?.finalData?.earningRule?.rules[0]?.data.map((data, index) =>
                                        index === 0
                                            ? {
                                                ...data,
                                                expirationRule: {
                                                    ...prevState?.finalData?.earningRule?.rules[0]?.data.expirationRule,
                                                    afterDuration: afterDuration,
                                                    afterSpecificNumber: afterSpecificNumber,
                                                    onAfter: onAfter,
                                                },
                                            }
                                            : data
                                    ),
                                }
                                : rules
                    ),
                },
            },
        }));
    }, [expirationRule]); //eslint-disable-line

    useEffect(() => {
        setRewardRatiodata({
            ...rewardratioData,
            EarnigWho: formateData.earningRule.rules[0].data[0].EarnigWho,
            RedeemType: formateData.earningRule.rules[0].data[0].RedeemType,
            RewardRatio: formateData.earningRule.rules[0].data[0].RewardRatio,
            expirationRule: {
                ...rewardratioData.expirationRule,
                onAfter: formateData.earningRule.rules[0].data[0].expirationRule.onAfter,
                afterSpecificNumber: formateData.earningRule.rules[0].data[0].expirationRule.afterSpecificNumber,
                afterDuration: formateData.earningRule.rules[0].data[0].expirationRule.afterDuration,
            },
        });
        let newdata = {};
        const infixRule = [];
        // const infixRule2 = [];

        formateData.earningRule.rules[0].infixRule.map((obj, id) => {
            return infixRule.push({
                id: id + 1,
                transactionAmount: obj.field.replace(/_/g, ""),
                andOr: "AND",
                greaterOrEqual: obj.op,
                amt: Array.isArray(obj.value) ? [] : obj.value,
                isFlag: Array.isArray(obj.value) ? false : true,
                quantity: Array.isArray(obj.value) ? obj.value[0] : obj.value,
                quantity1: Array.isArray(obj.value) && obj.value[1] ? obj.value[1] : "",
                type: obj?.type ? obj?.type : "string",
                position: 0,
            });
        });
        // setIncomingTransactions2({
        // 	...incomingTransactions2,
        // 	data: infixRule2,
        // 	EarnigWho: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].EarnigWho,
        // 	Value: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].Value,
        // 	RedeemType: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].RedeemType,
        // 	RewardRatio: formateData.earningRule.rules[1] && formateData.earningRule.rules[1].data[0].RewardRatio,
        // });
        setIncomingTransactions({
            ...incomingTransactions,
            EarnigWho: formateData.earningRule.rules[0].data[0].EarnigWho,
            Value: Number(formateData.earningRule.rules[0].data[0].Value),
            RedeemType: formateData.earningRule.rules[0].data[0].RedeemType,
            RewardRatio: Number(formateData.earningRule.rules[0].data[0].RewardRatio),
            data: infixRule,
        });
        newdata = data?.data?.schema?.properties?.transactionDetail?.properties;
        const temps = [];
        for (const property in newdata) {
            if (newdata[property].enum) {
                newdata[property].enum.map((val) => {
                    return temps.push({ label: val, value: val });
                });
            }
        }
        setFormData((prev) => ({ ...prev, amountOption: temps }));
    }, [formateData?.earningRule?.rules]); //eslint-disable-line
    // useEffect(() => {
    // 	let data = incomingTransactions?.data
    // 	setAdvanceRule({ ...advanceRule, data: data })
    // }, [incomingTransactions?.data])

    // const fetchVoucherTemplate = async () => {
    //     setLoader(true);
    //     const token = JSON.parse(localStorage.getItem("token_gen"));
    //     const response = await getVoucherTemplateByMerchantCode(token.access_token);
    //     setLoader(false);
    //     let temp = [];
    //     response?.data?.map((e) =>{
    //         temp.push({
    //             label: e.name,
    //             value: e.code,
    //             earningValue: e.earningValue,
    //         });
    //     });
    //     setFormData((prev) => ({ ...prev, voucherOption: temp }));
    // };
    useEffect(() => {
        setLoader(true);
        clearVoucherTemplate();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        //voucherTemplateDetail(token.access_token);
        setLoader(false);
    }, []);

    const handleVoucherData = () => {
        let temp = [];
        const currentDate = new Date();
        VoucherTemplate?.dataList?.map((e) => {
            if (e.isActive && new Date(e.voucherVisibilityDate) <= currentDate) {
                temp.push({
                    label: e.name,
                    value: e.code,
                    earningValue: e.earningValue,
                });
            }
        });
        setFormData((prev) => ({ ...prev, voucherOption: temp }));
    };

    useEffect(() => {
        handleVoucherData();
    }, [VoucherTemplate]);

    const handleConditions = (e, id, type) => {
        if (type === 0) {
            setIncomingTransactions({
                ...incomingTransactions,
                data: incomingTransactions?.data.map((obj, ind) => {
                    if (obj.id === id) return { ...obj, greaterOrEqual: e.value };
                    return obj;
                }),
            });
        } else {
            setIncomingTransactions2({
                ...incomingTransactions2,
                data: incomingTransactions2?.data.map((obj, ind) => {
                    if (obj.id === id) return { ...obj, greaterOrEqual: e.value };
                    return obj;
                }),
            });
        }
    };

    const handleAmt = (e, id) => {
        setIncomingTransactions({
            ...incomingTransactions,
            data: incomingTransactions?.data.map((obj, ind) => {
                if (obj.id === id) return { ...obj, amt: [e.value], quantity: "" };
                return obj;
            }),
        });
    };
    const onSelectChange = async (e, id, type) => {
        let newdata = {};
        newdata = await data?.data?.schema?.properties?.transactionDetail?.properties;
        let temp = [];
        for (const property in newdata) {
            if (property.toUpperCase() === e.value.toUpperCase() && newdata[property].enum) {
                newdata[property].enum.map((val) => {
                    return temp.push({ label: val, value: val });
                });
                if (type === 0) {
                    setIncomingTransactions({
                        ...incomingTransactions,
                        data: incomingTransactions?.data.map((obj, ind) => {
                            if (obj.id === id) return { ...obj, isFlag: true, transactionAmount: e.value, position: e.index, greaterOrEqual: "", quantity: "", quantity1: "" };
                            return obj;
                        }),
                    });
                } else {
                    setIncomingTransactions2({
                        ...incomingTransactions2,
                        data: incomingTransactions2?.data.map((obj, ind) => {
                            if (obj.id === id) return { ...obj, isFlag: true, transactionAmount: e.value };
                            return obj;
                        }),
                    });
                }
            } else {
                if (type === 0) {
                    let temp = Object.getOwnPropertyNames(newdata).find((data) => data.toLowerCase() === e.value.toLowerCase());
                    if (property === temp)
                        setIncomingTransactions({
                            ...incomingTransactions,
                            data: incomingTransactions?.data.map((obj, ind) => {
                                if (obj.id === id) return { ...obj, isFlag: false, transactionAmount: e.value, type: newdata[temp].type, position: e.index, greaterOrEqual: "", quantity: "", quantity1: "" };
                                return obj;
                            }),
                        });
                } else {
                    setIncomingTransactions2({
                        ...incomingTransactions2,
                        data: incomingTransactions2?.data.map((obj, ind) => {
                            if (obj.id === id) return { ...obj, isFlag: false, transactionAmount: e.value };
                            return obj;
                        }),
                    });
                }
            }
        }
        let tempStringCondition = [];
        for (const property in newdata) {
            if (property.toUpperCase() === e.value.toUpperCase()) {
                if (newdata[property].type === "string") {
                    tempStringCondition = stringCondition;
                } else if (newdata[property].type === "number") {
                    tempStringCondition = numberCondition;
                } else if (newdata[property].type === "boolean") {
                    tempStringCondition = booleanCondition;
                }
            }
        }
        setFormData({
            ...formData,
            amountOption: temp,
            conditions: tempStringCondition,
        });
    };

    const fetch = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let schemaName = await localStorage.getItem("schemaName");
        schemaName = schemaName.split(" ").join("");
        const response = await schema(token.access_token, schemaName);
        let newdata = response.data.schema.properties.transactionDetail.properties;
        let temp = [];
        let flag = 1;
        for (const property in newdata) {
            if (newdata[property].isrulesupported === true) {
                temp.push({
                    label: property,
                    value: property,
                    index: flag,
                });
            }
            flag += 1;
        }
        setData(response);
        setFormData((prev) => ({ ...prev, transactionOption: temp }));
    };

    const onAddRule = (id) => {
        if (id === 1) {
            // setIncomingTransactionsCount(incomingTransactionsCount + 1);
            setIncomingTransactions({
                ...incomingTransactions,
                data: [
                    ...incomingTransactions.data,
                    incomingTransactions.data[incomingTransactions.data.length - 1].andOr,
                    {
                        id: incomingTransactions.data[incomingTransactions.data.length - 1].id + 1,
                        transactionAmount: "",
                        greaterOrEqual: "",
                        quantity: "",
                        quantity1: "",
                        amt: [],
                        isFlag: false,
                        andOr: toggle,
                        position: 0,
                    },
                ],
            });
        } else {
            setIncomingTransactionsCount2(incomingTransactionsCount2 + 1);
            setIncomingTransactions2({
                ...incomingTransactions2,
                data: [
                    ...incomingTransactions2.data,
                    {
                        id: incomingTransactionsCount2 + 1,
                        transactionAmount: "",
                        greaterOrEqual: "",
                        quantity: "",
                        amt: [],
                        isFlag: false,
                        andOr: "AND",
                    },
                ],
            });
        }
    };

    const onDeleteRule = async (id) => {
        let incomingTransactions_data = incomingTransactions?.data;
        incomingTransactions_data = await incomingTransactions_data.filter((choice, i) => {
            return i !== id - 1 && i !== id;
        });
        setIncomingTransactions({ ...incomingTransactions, data: incomingTransactions_data });
    };

    const onDeleteRule2 = (id) => {
        let incomingTransactions_data2 = incomingTransactions2?.data;
        incomingTransactions_data2 = incomingTransactions_data2.filter((choice, i) => {
            return choice.id !== id;
        });
        setIncomingTransactions2({
            ...incomingTransactions2,
            data: incomingTransactions_data2,
        });
    };

    const onInputQuantity = (e, i) => {
        setIncomingTransactions({
            ...incomingTransactions,
            data: incomingTransactions?.data.map((obj, ind) => {
                if (ind === i) return { ...obj, [e.target.name]: e.target.value, amt: [] };
                return obj;
            }),
        });
    };

    const onInputQuantity2 = (e, i) => {
        setIncomingTransactions2({
            ...incomingTransactions2,
            data: incomingTransactions2?.data.map((obj, ind) => {
                if (ind === i) return { ...obj, quantity: e.target.value, amt: [] };
                return obj;
            }),
        });
    };

    // const handleToggle = () => {
    // 	setTogglee((prev) => !prev);
    // };

    const handleOnChainge = (e, type) => {
        if (type === 1) {
            setIncomingTransactions({
                ...incomingTransactions,
                [e.target.name]: e.target.value,
            });
            // setRewardRatiodata({ ...rewardratioData, [e.target.name]: e.target.value })
        } else
            setIncomingTransactions2({
                ...incomingTransactions2,
                [e.target.name]: e.target.value,
            });
    };

    const handleAndOr = (e, id) => {
        if (id === 1) {
            setToggle(e);
            setIncomingTransactions({
                ...incomingTransactions,
                data: incomingTransactions?.data.map((obj, ind) => {
                    if (ind === incomingTransactions?.data.length - 1) return { ...obj, andOr: e };
                    return obj;
                }),
            });
        } else {
            setToggleAnd2(e);
            setIncomingTransactions2({
                ...incomingTransactions2,
                data: incomingTransactions2?.data.map((obj, ind) => {
                    if (ind === incomingTransactions2?.data.length - 1) return { ...obj, andOr: e };
                    return obj;
                }),
            });
        }
    };

    // const handleAddReferalReward = () => {
    //     const rules = [];
    //     finalData.finalData.earningRule.rules.map((val) => {
    //         rules.push(val);
    //     });

    //     rules.push(finalData.finalData.earningRule.rules[0]);
    //     setAddDelBtn(false);
    //     setFinalData((prevState) => ({
    //         finalData: {
    //             ...prevState.finalData,
    //             earningRule: {
    //                 ...prevState.finalData.earningRule,
    //                 rules: rules,
    //             },
    //         },
    //     }));
    // };

    const handleAdvanceRule = () => {
        if (isAdvance) {
            setAdvanceRule({
                field: "",
                input: "",
                value: "",
                value2: "",
                type: "",
                everyValue: "",
                isEvery: false,
            });
        }
        setIsAdvance(!isAdvance);
    };
    const returnOption = (type) => {
        if (type === "string") return stringCondition;
        else if (type === "number") return numberCondition;
        else if (type === "boolean") return booleanCondition;
        else return [];
    };

    const handleCommTab = () => {
        handleTabChange({ key: "Communication Flow" });
    };
    const onSubmit = async () => {
        if (isAdvance && !advanceRule.field && !advanceRule.isEvery) {
            setFormError("Please select either a Property or enable Every.");
            return;
        }
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const response = await campaign(token.access_token, finalData.finalData);
        if (response.message === "Record Created Successfully.") {
            localStorage.setItem("code", encrypt(response.data.code));
            localStorage.setItem("key", encrypt("Communication Flow"));
            setLoader(false);
            swal({
                position: "center",
                icon: "success",
                title: "Create Campaign Successfully.",
                showConfirmButton: false,
                timer: 5000,
            });
            handleCommTab();
            navigate("/my-campaigns/edit-campaign", { state: { data: response.data, type: "", tab: "Communication Flow" } });
        } else {
            setLoader(false);
            swal({
                title: " Oops!",
                text: " Something went wrong please try again.",
                icon: "error",
                button: "oh no!",
                timer: 5000,
            });
        }
    };
    let complete=false
    useEffect(() => { 
        
        const handleLoad=()=>{ 

         const steps = [
            {
                element: document.getElementById('Earning_rules'),
                title: 'rules ',
                intro: "Earnings rules for campaigns to align them with your business goals."
              },
           
              
    
         ]
         intro.setOptions({
             steps: steps,
             exitOnOverlayClick: false,
            doneLabel:"Next",
            tooltipClass: 'customTooltip'
           });
     
         intro.start();
         } 
            //  setIsAdvance(true)
            // setAdvanceRule({
            //     field: "",
            //     input: "",
            //     value: "",
            //     value2: "",
            //     type: "",
            //     everyValue: "",
            //     isEvery: true,
            // });
            
         setTimeout(()=>{
            handleLoad()
            
         },500)
        
         
       }, []);
       intro.oncomplete(function(element) {
        complete=true
    
     
    });
  
    intro.onexit(() => {
        setTimeout(()=>{
             if(!exitOnLast)   props.onCompleteIntro('Communication Flow')
      
  },900)
})
      intro.onchange(function(element) {
        switch (this._currentStep){
            case 0:
              setTimeout(()=>{
                let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
                cross?.addEventListener('click', function() {
                exitOnLast=true
                localStorage.setItem('firstLogin','');  
                setShowmodal(true)      
                });
              },600)  

              
                   break;
                 
           }

   });
   const popups = document.getElementsByClassName("introjs-tooltip customTooltip introjs-floating")
 if(popups.length)  popups[0].style.position='bottom'
   
    return (
        <ValidatorForm className="customForm" ref={formRef} onSubmit={(e) => onSubmit(e)}>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {showModal
 && <ModalCard show={showModal}/>}
            <div  className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="dashboard__customFormBox">
                        {/* <div className="dashboard__heading">
							<div className="dashboard__left">
								<h5>
									Transactions Rule{" "}
									<span title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
										<i className="fa-solid fa-circle-info"></i>
									</span>
								</h5>
							</div>
							<div className="dashboard__right">
								{
									addDelBtn &&
									<button type="button" className="btn btn-primary" onClick={handleAddReferalReward}>
										Add Referral Rewards
									</button>
								}
							</div>
						</div> */}
                        <div id="Earning_rules" className="dashboard__customFormCard">
                            <div  className="dashboard__top">
                                <div className="dashboard__left">
                                    <button type="button" className={toggle === "AND" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => handleAndOr("AND", 1)}>
                                        AND
                                    </button>
                                    <button type="button" className={toggle === "OR" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => handleAndOr("OR", 1)}>
                                        OR
                                    </button>
                                </div>
                                <div className="dashboard__right">
                                    <button type="button" id='add_rules' className="btn btn-outline-success" onClick={() => onAddRule(1)}>
                                        <i className="fa-solid fa-circle-plus"></i> Add Rule
                                    </button>
                                </div>
                            </div>
                            <div className="dashboard__middle">
                                {incomingTransactions?.data?.map(
                                    (col, i) =>
                                        typeof col === "object" && (
                                            <div className="row" key={col.id}>
                                                {i === 0 ? null : (
                                                    <span className="dashboard__close" onClick={() => onDeleteRule(i)}>
                                                        <i className="fas fa-close"></i>
                                                    </span>
                                                )}
                                                <div id='drop1' className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        {/* <label>Transaction Amount</label> */}
                                                        <SelectValidator validators={["required"]} errorMessages={["Please select a value "]} className="custom-ReactSelect bordered" placeholder="Select" name="category" value={selectValueGetter(transactionOption, col.transactionAmount)} options={transactionOption} onChange={(e) => onSelectChange(e, col.id, 0)} />
                                                    </div>
                                                </div>
                                                <div id='drop2' className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        {/* <label>Condition</label> */}
                                                        <SelectValidator validators={["required"]} errorMessages={["Please select a value "]} placeholder="Select" className="custom-ReactSelect bordered" name="greaterOrEqual" value={selectValueGetter(returnOption(col.type), col.greaterOrEqual) ? selectValueGetter(returnOption(col.type), col.greaterOrEqual) : ""} options={returnOption(col.type)} onChange={(e) => handleConditions(e, col.id, 0)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        {/* <label>Amount</label> */}
                                                        {col?.isFlag ? <SelectValidator placeholder="Select" className="custom-ReactSelect bordered" name="amt" value={selectValueGetter(amountOption, col.amt[0] || "")} options={amountOption} onChange={(e) => handleAmt(e, col.id)} /> : col.type === "string" ? <TextValidator value={col.quantity} name="quantity" className="form-control" validators={["required"]} errorMessages={["Please Enter value "]} onChange={(e) => onInputQuantity(e, i)} /> : <TextValidator pattern="^(?:[1-9]\d*|'')$" value={col?.quantity > 0 ? col?.quantity : ""} className="form-control" name="quantity" validators={["required"]} errorMessages={["Please Enter value "]} onChange={(e) => e.target.validity.valid && onInputQuantity(e, i)} />}
                                                    </div>
                                                </div>
                                                {col.greaterOrEqual === "between" && (
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                        <div className="form-group">
                                                            {/* <label>Amount</label> */}
                                                            <TextValidator
                                                                pattern="^(?:[1-9]\d*|'')$"
                                                                value={col?.quantity1 > 0 ? col?.quantity1 : ""}
                                                                // placeholder="1001"
                                                                name="quantity1"
                                                                className="form-control"
                                                                onChange={(e) => e.target.validity.valid && onInputQuantity(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                    <div id='middle'  className="dashboard__customFormBox">
                        <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                                <div className="dashboard__heading">
                                    <div className="dashboard__left">
                                        <h6>Earning</h6>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            <label>Earning Type</label>
                                            <SelectValidator
                                                placeholder="Select"
                                                className="custom-ReactSelect bordered"
                                                name="RedeemType"
                                                validators={["required"]}
                                                errorMessages={["Please select a value "]}
                                                value={selectValueGetter(optionPoint, incomingTransactions.RedeemType)}
                                                options={optionPoint}
                                                onChange={(e) => {
                                                    setIncomingTransactions({
                                                        ...incomingTransactions,
                                                        RedeemType: e.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            {incomingTransactions?.RedeemType === "Voucher" ? (
                                                <>
                                                    <label>Voucher</label>
                                                    <SelectValidator
                                                        placeholder="Select"
                                                        className="custom-ReactSelect bordered"
                                                        name="Voucher"
                                                        validators={["required"]}
                                                        errorMessages={["Please select a value "]}
                                                        value={selectValueGetter(voucherOption, incomingTransactions.VoucherTemplate)}
                                                        options={voucherOption}
                                                        onChange={(e) => {
                                                            setIncomingTransactions({
                                                                ...incomingTransactions,
                                                                VoucherTemplate: e.value,
                                                                VoucherEarningValue: e.earningValue,
                                                            });
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <label>value</label>
                                                    <TextValidator placeholder="Value" className="form-control" pattern="^(?:[1-9]\d*|'')$" name="Value" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]} value={incomingTransactions.Value > 0 ? incomingTransactions.Value : ""} onChange={(e) => e.target.validity.valid && handleOnChainge(e, 1)} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            {incomingTransactions?.RedeemType !== "Voucher" && (
                                                <>
                                                    <label>Reward Ratio</label>
                                                    <TextValidator placeholder="Reward Ratio" className="form-control" pattern="^(?:[1-9]\d*|'')$" name="RewardRatio" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]} value={incomingTransactions.RewardRatio > 0 ? incomingTransactions.RewardRatio : ""} onChange={(e) => e.target.validity.valid && handleOnChainge(e, 1)} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {incomingTransactions.RedeemType === "Percentage-Point" && (
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <div className="form-group">
                                                <label>Cap Limits</label>
                                                <TextValidator placeholder="Cap Limits" className="form-control" pattern="^(?:[1-9]\d*|'')$" name="CapLimit" maxLength="6" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]} value={incomingTransactions.CapLimit > 0 ? incomingTransactions.CapLimit : ""} onChange={(e) => e.target.validity.valid && handleOnChainge(e, 1)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <div className="form-group">
                                                <label>Transaction FIeld</label>
                                                <SelectValidator
                                                    placeholder="Select"
                                                    className="custom-ReactSelect bordered"
                                                    name="TransactionField"
                                                    validators={["required"]}
                                                    errorMessages={["Please select a value "]}
                                                    options={transactionOption}
                                                    value={selectValueGetter(transactionOption, incomingTransactions.TransactionField)}
                                                    onChange={(e) =>
                                                        setIncomingTransactions({
                                                            ...incomingTransactions,
                                                            TransactionField: e.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!addDelBtn && (
                        <div className="dashboard__customFormBox">
                            <div className="dashboard__heading">
                                <div className="dashboard__left">
                                    <h5>
                                        Transactions Rule{" "}
                                        <span title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                                            <i className="fa-solid fa-circle-info"></i>
                                        </span>
                                    </h5>
                                </div>
                                <div className="dashboard__right">
                                    <button type="button" className="btn btn-primary">
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <div className="dashboard__customFormCard">
                                <div className="dashboard__top">
                                    <div className="dashboard__left">
                                        <button type="button" className={toggleAnd2 === "AND" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("AND", 2)}>
                                            AND
                                        </button>
                                        <button type="button" className={toggleAnd2 === "OR" ? "btn btn-outline-primary active" : "btn btn-outline-primary"} onClick={() => handleAndOr("OR", 2)}>
                                            OR
                                        </button>
                                    </div>
                                    <div className="dashboard__right">
                                        <button type="button" id='add_rules' className="btn btn-outline-success" onClick={() => onAddRule(2)}>
                                            <i className="fa-solid fa-circle-plus"></i> Add Rule
                                        </button>
                                    </div>
                                </div>
                                <div className="dashboard__middle">
                                    {incomingTransactions2?.data.map((col, i) => (
                                        <div className="row" key={col.id}>
                                            {i === 0 ? null : (
                                                <span className="dashboard__close" onClick={() => onDeleteRule2(col.id)}>
                                                    <i className="fas fa-close"></i>
                                                </span>
                                            )}
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>Transaction Amount</label>
                                                    <SelectValidator placeholder="Transaction-amount" className="custom-ReactSelect bordered" name="category" value={selectValueGetter(transactionOption, col.transactionAmount)} options={transactionOption} onChange={(e) => onSelectChange(e, col.id, 1)} />
                                                    <label>Transaction Amount</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>Condition</label>
                                                    <SelectValidator
                                                        // placeholder="greater OR equal"
                                                        className="custom-ReactSelect bordered"
                                                        name="category"
                                                        value={selectValueGetter(conditions, col.greaterOrEqual)}
                                                        options={conditions}
                                                        onChange={(e) => handleConditions(e, col.id, 1)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>Amount</label>
                                                    {col?.isFlag ? (
                                                        <SelectValidator
                                                            // placeholder="greater OR equal"
                                                            className="custom-ReactSelect bordered"
                                                            name="amt"
                                                            value={selectValueGetter(amountOption, col.amt[0] || "")}
                                                            options={amountOption}
                                                            onChange={(e) => handleAmt(e, col.id)}
                                                        />
                                                    ) : (
                                                        <TextValidator
                                                            value={col.quantity}
                                                            // placeholder="100"
                                                            className="form-control"
                                                            onChange={(e) => onInputQuantity2(e, i)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div  className="dashboard__middle">
                                    <div className="dashboard__heading">
                                        <div className="dashboard__left">
                                            <h6>Earning</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <div className="form-group">
                                                <label>Earning Type</label>
                                                <SelectValidator
                                                    placeholder="Fix - Point"
                                                    value={selectValueGetter(optionPoint, incomingTransactions2.RedeemType)}
                                                    options={optionPoint}
                                                    onChange={(e) =>
                                                        setIncomingTransactions2({
                                                            ...incomingTransactions2,
                                                            RedeemType: e.value,
                                                        })
                                                    }
                                                    className="custom-ReactSelect bordered"
                                                    name="fixPoint"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <div className="form-group">
                                                {/* <label>Value</label> */}
                                                <TextValidator placeholder="Value" className="form-control" name="Value" value={incomingTransactions2.Value} onChange={(e) => handleOnChainge(e, 2)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                            <div className="form-group">
                                                {/* <label>Reward Ratio </label> */}
                                                <TextValidator placeholder="Reward Ratio" className="form-control" name="RewardRatio" value={incomingTransactions2.RewardRatio} onChange={(e) => handleOnChainge(e, 12)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <p className="text-end">
                        <span style={{ color: "#86b7fe" }} onClick={() => handleAdvanceRule()}>
                            Advance Rules ?
                        </span>
                    </p>
                </div>
                {isAdvance && (
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="dashboard__customFormBox">
                            <div className="dashboard__customFormCard">
                                <div className="dashboard__middle">
                                    <div className="row">
                                        <div className="dashboard__heading">
                                            <div className="dashboard__left">
                                                <h6>Advance rule on Incoming transactions</h6>
                                            </div>
                                        </div>

                                        <div id='advance_rule' className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>Property</label>
                                                    <SelectValidator
                                                        className="custom-ReactSelect bordered"
                                                        placeholder="Select"
                                                        name="field"
                                                        value={selectValueGetter(transactionOptionAdv, advanceRule.field)}
                                                        options={transactionOptionAdv}
                                                        onChange={(e) =>
                                                            setAdvanceRule({
                                                                ...advanceRule,
                                                                field: e.value,
                                                            })
                                                        }
                                                    // disabled
                                                    />
                                                </div>
                                            </div>
                                            {advanceRule.field !== "" && (
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        <label>Operator</label>
                                                        <SelectValidator
                                                            placeholder="Select"
                                                            className="custom-ReactSelect bordered"
                                                            name="greaterOrEqual"
                                                            // disabled
                                                            validators={["required"]}
                                                            errorMessages={["Please select field"]}
                                                            value={selectValueGetter(numberCondition, advanceRule.input)}
                                                            options={numberCondition}
                                                            onChange={(e) =>
                                                                setAdvanceRule({
                                                                    ...advanceRule,
                                                                    input: e.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {advanceRule.field !== "" && (
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        <label>Value</label>
                                                        <TextValidator
                                                            value={advanceRule.value}
                                                            pattern="^(?:[1-9]\d*|'')$"
                                                            className="form-control"
                                                            validators={["required"]}
                                                            errorMessages={["Please Enter value "]}
                                                            onChange={(e) =>
                                                                setAdvanceRule({
                                                                    ...advanceRule,
                                                                    value: e.target.validity.valid ? e.target.value : advanceRule.value,
                                                                })
                                                            }
                                                        // disabled
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {/* 
											{(advanceRule.input === "between") && (<div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
												<div className="form-group">
													<label>Value</label>
													<TextValidator
														value={advanceRule.value2}
														pattern="^(?:[1-9]\d*|'')$"
														className="form-control"
														onChange={(e) => setAdvanceRule({
															...advanceRule,
															value2: e.target.validity.valid ? e.target.value : advanceRule.value2,
														})}
													// disabled
													/>
												</div>
											</div>)} */}
                                        </div>
                                            <div  id='advance_earning'>
                                        {formPage !== "voucher" && (
                                            <div className="dashboard__heading">
                                                <div className="dashboard__left">
                                                    <h6>Advance rule on Earning Rule</h6>
                                                </div>
                                            </div>
                                        )}

                                        {formPage !== "voucher" && (
                                            <div  className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className="form-group withSwitch" style={{ margin: "0 0 20px" }}>
                                                    <label>
                                                        Every:
                                                        <Switch
                                                            name="isEvery"
                                                            value={advanceRule.isEvery}
                                                            checked={advanceRule.isEvery} 
                                                            onChange={() =>
                                                                setAdvanceRule({
                                                                    ...advanceRule,
                                                                    isEvery: !advanceRule.isEvery,
                                                                    everyValue: "",
                                                                    type: "",
                                                                })
                                                            }
                                                            onColor={advanceRule.isEvery ? "#50BC14" : "#E50000"}
                                                            onHandleColor="#50BC14"
                                                            handleDiameter={30}
                                                            uncheckedIcon={true}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48}
                                                            className="react-switch"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {(formError && !advanceRule.field && !advanceRule.isEvery) && (
                                            <p className="error-message" style={{ color: "red" }}>
                                                {formError}
                                            </p>
                                        )}
                                        </div>


                                        {advanceRule.isEvery && formPage !== "voucher" && (
                                            <>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        {/* <label>Transaction Amount</label> */}
                                                        <SelectValidator
                                                            className="custom-ReactSelect bordered"
                                                            placeholder="Select"
                                                            name="type"
                                                            validators={["required"]}
                                                            errorMessages={["Please select field"]}
                                                            value={selectValueGetter(transactionOption, advanceRule.type)}
                                                            options={transactionOption}
                                                            onChange={(e) =>
                                                                setAdvanceRule({
                                                                    ...advanceRule,
                                                                    type: e.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div className="form-group">
                                                        {/* <label>Reward Ratio</label> */}
                                                        <TextValidator
                                                            placeholder="Every Value"
                                                            className="form-control"
                                                            name="everyValue"
                                                            pattern="^(?:[1-9]\d*|'')$"
                                                            value={advanceRule.everyValue}
                                                            onChange={(e) =>
                                                                setAdvanceRule({
                                                                    ...advanceRule,
                                                                    everyValue: e.target.validity.valid ? e.target.value : advanceRule.everyValue,
                                                                })
                                                            }
                                                            validators={["required"]}
                                                            errorMessages={["Please Enter value "]}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div id='onAfter'className="col-sm-12 col-md-12 col-lg-12">
                    <div className="dashboard__customFormBox">
                        <div className="dashboard__heading">
                            <div className="dashboard__left">
                                <h5>Expiration</h5>
                            </div>
                        </div>
                        <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                        <div className="form-group">
                                            <label>On / After</label>
                                            <SelectValidator
                                                placeholder="Select"
                                                validators={["required"]}
                                                errorMessages={["Please select a value "]}
                                                className="custom-ReactSelect bordered"
                                                name="onAfter"
                                                value={selectValueGetterBoolean(optionOnAfter, onAfter)}
                                                options={optionOnAfter}
                                                onChange={(e) =>
                                                    setRewardRatiodata({
                                                        ...rewardratioData,
                                                        expirationRule: {
                                                            ...rewardratioData.expirationRule,
                                                            onAfter: e.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    {onAfter === true ? (
                                        <>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>Number</label>
                                                    <TextValidator
                                                        placeholder="365"
                                                        pattern="^(?:[1-9]\d*|'')$"
                                                        className="form-control"
                                                        name="afterSpecificNumber"
                                                        validators={["required", "matchRegexp:^[1-9][0-9]*$"]}
                                                        errorMessages={["Field can't be empty", "please enter a valid value (greater than zero)"]}
                                                        value={afterSpecificNumber > 0 ? afterSpecificNumber : ""}
                                                        onChange={(e) =>
                                                            e.target.validity.valid &&
                                                            setRewardRatiodata({
                                                                ...rewardratioData,
                                                                expirationRule: {
                                                                    ...rewardratioData.expirationRule,
                                                                    afterSpecificNumber: parseNumeric(e.target.value),
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>After</label>
                                                    <SelectValidator
                                                        placeholder="Select"
                                                        className="custom-ReactSelect bordered"
                                                        name="afterDuration"
                                                        validators={["required"]}
                                                        errorMessages={["Please select a value "]}
                                                        value={selectValueGetter(optionAfter, afterDuration)}
                                                        options={optionAfter}
                                                        onChange={(e) =>
                                                            setRewardRatiodata({
                                                                ...rewardratioData,
                                                                expirationRule: {
                                                                    ...rewardratioData.expirationRule,
                                                                    afterDuration: e.value,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                <div className="form-group">
                                                    <label>On (Specific Date)</label>
                                                    <TextValidator
                                                        placeholder="365"
                                                        className="form-control"
                                                        name="onSpecificDate"
                                                        type="date"
                                                        validators={["required"]}
                                                        errorMessages={["Please select a date "]}
                                                        value={getDate(onSpecificDate, "YYYY-MM-DD")}
                                                        onChange={(e) =>
                                                            setRewardRatiodata({
                                                                ...rewardratioData,
                                                                expirationRule: {
                                                                    ...rewardratioData.expirationRule,
                                                                    onSpecificDate: e.target.value,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="button-section">
                        <Link to={"/my-campaigns"}>
                            <button type="button" className="btn btn-danger">
                                Cancel
                            </button>
                        </Link>
                        <button type="submit"id='create_cmpain' disabled={true} className="btn btn-primary">
                            Create Campaign
                        </button>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    );
});
const mapStateToProps = (state) => ({
    VoucherTemplate: state.VoucherTemplate,
});
const mapDispatchToProps = {
    voucherTemplateDetail,
    clearVoucherTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEarningRulesTabLoyalty);
