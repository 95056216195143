import React, { useState, useEffect, useMemo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { getTransaction, clearTransaction, searchTransaction } from "../../../actions/transaction";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";
const Paginated = ({ columns, data, Transaction, getTransaction, clearTransaction, searchTransaction,loader,setLoader }) => {
    const [selectPage, setSelectPage] = useState();
    const [selectedPageIndex, setSelectedPageIndex] = useState(1);
    const [pageArray, setPageArray] = useState();
    const [transactionCount, setTransactionCount] = useState();

    const { getTableProps, getTableBodyProps, headerGroups, rows, page, /* nextPage, previousPage, canPreviousPage, canNextPage, pageOptions,gotoPage,*/ state, setPageSize, prepareRow, setGlobalFilter } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 50 },
        },
        useGlobalFilter,
        usePagination
    );

    const { globalFilter, /* pageIndex,*/ pageSize } = state;
    const [inputField, setInputField] = useState(globalFilter);
    const [visibleRowCount, setVisibleRowCount] = useState(pageSize);
    const [tempValue, setTempValue] = useState("");

    useEffect(() => {
        if (!rows.length && tempValue) {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            searchTransaction(token?.access_token, tempValue);
        }
    }, [tempValue]); // eslint-disable-line

    const handleInputChange = (e) => {
        const value = e.target.value;
        setTempValue(value);
        setGlobalFilter(value);
        if (value === "") {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
            let id = localStorage.getItem("Id");
            getTransaction(token?.access_token, id, tempPageIndex, 50);
        }
    };
    const debouncedResults = useMemo((e) => {
        return debounce(handleInputChange, 50);
    }, []); // eslint-disable-line

    useEffect(() => {
        setGlobalFilter(inputField);
    }, [inputField]); // eslint-disable-line

    useEffect(() => {
        setTransactionCount(Transaction.data.totalCount);
    }, [Transaction]); // eslint-disable-line

    useEffect(() => {
        if (transactionCount) {
            let tempTransactionCount = transactionCount / 50;
            let temp = Math.ceil(tempTransactionCount);
            setSelectPage(temp);
        } else {
            setSelectPage(0);
        }
    }, [transactionCount]);

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
        if (tempPageIndex !== 1) {
            fetch();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const temp = Array.from({ length: selectPage }, (_, index) => index + 1);
        setPageArray(temp);
    }, [selectPage]);

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
        tempPageIndex ? setSelectedPageIndex(tempPageIndex) : setSelectedPageIndex(1);
    }, []);

    const handlePageIndex = (event) => {
        if (event.target.value !== "") {
            const number = Number(event.target.value);
            setSelectedPageIndex(number);
            localStorage.setItem("TransPageIndex", JSON.stringify(number));
            fetch();
            // gotoPage(number);
        }
    };

    const fetch = async () => {
        setLoader(true);
        await clearTransaction();
        let tempPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getTransaction(token?.access_token, id, tempPageIndex, 50);
        setLoader(false);
    };

    const handleNext = () => {
        if (selectedPageIndex < pageArray?.length) {
            setSelectedPageIndex(selectedPageIndex + 1);
            localStorage.setItem("TransPageIndex", JSON.stringify(selectedPageIndex + 1));
            fetch();
            // nextPage();
        }
    };
    const handlePrevious = () => {
        if (selectedPageIndex > 1) {
            setSelectedPageIndex(selectedPageIndex - 1);
            localStorage.setItem("TransPageIndex", JSON.stringify(selectedPageIndex - 1));
            fetch();
            // previousPage();
        }
    };

    // const handleShowRecords = async (e) => {
    //     setPageSize(Number(e.target.value));
    //     setPageLimit(Number(e.target.value));
    // };

    const memoGlobleFilter = useMemo(() => {
        return <GlobalFilter filter={inputField} setFilter={setInputField} page={page} searchTransaction={searchTransaction} getTransaction={getTransaction} debouncedResults={debouncedResults} />;
    }, [globalFilter, page, searchTransaction]); // eslint-disable-line
    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}

            <div className="customTable">
                <div className="customTable__header">
                    <div className="customTable__left">
                        {/* <div className="customTable__showingRecords">
                            <div className="form-group">
                                <label>Show Records:</label>
                                <select
                                    className="form-control selectArrow"
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                        setVisibleRowCount(Number(e.target.value));
                                    }}
                                >
                                    {[10, 20, 50, 100, 500].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                    </div>
                    <div className="customTable__right">{memoGlobleFilter}</div>
                </div>
                <div className="customTable__body">
                    <div className="table-responsive">
                        {data?.length ? (
                            <InfiniteScroll
                                dataLength={visibleRowCount}
                                next={() => {
                                    setVisibleRowCount((prevRowCount) => prevRowCount + pageSize);
                                }}
                                hasMore={visibleRowCount < rows.length}
                                // loader={<h4>Loading...</h4>}
                                // endMessage={<p>No more records to load.</p>}
                            >
                                <table className="table table-striped table-bordered" {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                        {rows.slice(0, visibleRowCount).map((row, rowIndex) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={rowIndex}>
                                                    {row.cells.map((cell) => {
                                                        return <td {...cell.getCellProps()}>{cell.render("Cell") ? cell.render("Cell") : "Null"}</td>;
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        ) : (
                            <table className="table table-striped table-bordered" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={9}>
                                            <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="customTable__footer">
                    <div className="customTable__left">
                        <div className="customTable__totalTransactions">
                            <p>
                                Total Transactions: <span>{rows.length}</span>
                            </p>
                        </div>
                    </div>
                    {rows.length === 1 ?
                        null : 
                    <div className="customTable__right">
                        <div className="customTable__top">
                            <div className="customTable__totalPages">
                                {pageArray && (
                                    <p>
                                        Page
                                        <select className="form-control selectArrow" value={selectedPageIndex} onChange={handlePageIndex}>
                                            {pageArray.length ? (
                                                pageArray.map((value) => (
                                                    <option key={value} value={value}>
                                                        {value}
                                                    </option>
                                                ))
                                            ) : (
                                                <option key={0} value={0}>
                                                    {0}
                                                </option>
                                            )}
                                        </select>
                                        of <span>{pageArray?.length}</span>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="customTable__bottom">
                            <div className="customTable__buttons">
                                {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{"First"}
							</span> */}

                                <span className="btn btn-outline-primary" onClick={() => handlePrevious()} disabled={selectedPageIndex > 1 ? false : true}>
                                    {/* <span className="btn btn-outline-primary" onClick={handlePrevious}> */}
                                    {"Previous"}
                                </span>
                                {/* <span className="btn btn-outline-primary" onClick={() => nextPage()} disabled={!canNextPage}> */}
                                <span className="btn btn-outline-primary" onClick={handleNext} disabled={pageArray?.length > 0 ? (selectedPageIndex === pageArray?.length ? true : false) : true}>
                                    {"Next"}
                                </span>
                                {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{"Last"}
							</span> */}
                            </div>
                        </div>
                    </div>
}
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Transaction: state.Transaction,
});
const mapDispatchToProps = {
    getTransaction,
    clearTransaction,
    searchTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Paginated);
